import { useEffect } from 'react';
import arePassiveEventsSupported from 'are-passive-events-supported';
import useLatest from 'use-latest';

var MOUSEDOWN = 'mousedown';
var TOUCHSTART = 'touchstart';
var events = [MOUSEDOWN, TOUCHSTART];

var getOptions = function getOptions(event) {
  if (event !== TOUCHSTART) {
    return;
  }

  if (arePassiveEventsSupported()) {
    return {
      passive: true
    };
  }
};

function useOnClickOutside(ref, handler) {

  var handlerRef = useLatest(handler);
  useEffect(function () {
    if (!handler) {
      return;
    }

    var listener = function listener(event) {
      if (!ref.current || !handlerRef.current || ref.current.contains(event.target)) {
        return;
      }

      handlerRef.current(event);
    };

    events.forEach(function (event) {
      document.addEventListener(event, listener, getOptions(event));
    });
    return function () {
      events.forEach(function (event) {
        document.removeEventListener(event, listener, getOptions(event));
      });
    };
  }, [!handler]);
}

export default useOnClickOutside;
